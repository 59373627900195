import fetch from 'isomorphic-fetch';
import { getContentAPIURL } from '../lib/content-api-utils';
import Logger from '../lib/logger';

const logger = Logger.get('fetchPageContent');
/**
 * Retrieve the content for a page asynchronously via the Fetch API.
 * @param {string} pageUrl Content URL for the page.
 * @returns {Promise} A promise to return the page data as given in the response body.
 */
export async function fetchPageContent(pageUrl) {
	const fullUrl = getContentAPIURL() + 'page' + pageUrl;
	console.log('request made', {
		file: 'fetchPageContent',
		url: fullUrl,
		path: pageUrl,
	});
	try {
		const response = await fetch(fullUrl);
		return response.json();
	} catch (error) {
		logger.error(error);
	}
	return {};
}

export async function fetchContent(pageUrl) {
	const fullUrl = getContentAPIURL() + pageUrl;
	try {
		const response = await fetch(fullUrl);
		return response.json();
	} catch (error) {
		logger.error(error);
	}
	return {};
}
